
import {
    IonContent,
    IonHeader,
    IonPage,
    IonModal,
    IonToolbar,
    IonButtons,
    IonRow,
    IonButton,
    IonIcon,
    IonSpinner,
    IonLabel,
    IonCol,
    IonSearchbar, IonCheckbox,
} from "@ionic/react";
import React, {useState, useEffect} from "react";
import NavigationMenu from "../../../components/Header/NavigationMenu/NavigationMenu";
import useFilter from "../../../hooks/useFilter";
import {projectService, subProjectService, taskService} from "../../../services";
import "./SubProjectDetails.scss";
import RecapList from "../../../components/Task/List/RecapList";
import Select from "react-select";
import {
    funnelOutline,
    chevronDownOutline,
    arrowForwardOutline,
    folderOutline,
    addOutline,
    refresh, arrowDown, arrowUp, closeSharp
} from "ionicons/icons";
import useTaskFilter from "../../../hooks/useTaskFilter";
import ArrayService from "../../../services/ArrayService";
import { Menu } from '../../../components/Header/TitleOptionSearch/TitleOptionSearch';
import Expandable from "../../../components/SubProject/Expandable";
import DocumentItem from "../../../components/Document/DocumentItem";
import ReturnOfExperienceService from "../../../services/Api/ReturnOfExperienceService";
import ListRexModal from "../../../components/Task/Rex/ListRexModal";
import {ITask} from "../../../Interface/Task";
import MilestoneSignatureTaskModal from "../../../components/Task/MilestoneSignatureTaskModal";
import { Provider as SubprojectContextProvider } from "../../../contexts/SubprojectDetailContext";

const SubProjectDetails: React.FC = ({ match }: any) => {
  const LSTest = localStorage.getItem(`taskfilter`)
  if (!LSTest || LSTest === 'null') {
      localStorage.setItem(`taskfilter`, "all");
  }
  const filterName = localStorage.getItem(`taskfilter`)!;

  const [isModalDisplayed, setModalDisplayed] = useState<boolean>(false);
  const [modalTaskFolder, setModalTaskFolder] = useState<string>("");

  const [isRexModalDisplayed, setRexModalDisplayed] = useState<boolean>(false);
  const [isSignatureModalDisplayed, setSignatureModalDisplayed] = useState<boolean>(false);
  const [targetTask, setTargetTask] = useState<any>(null);
  const [taskRexes, setTaskRexes] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const [tagsSearchValue, setTagsSearchValue] = useState('');
  const [project, setProject]: any = useState({});
  const [subProject, setSubProject]: any = useState({});
  const [bookletOptions, setBookletOptions]: any = useState([]);
  const [tagOptions, setTagOptions]: any = useState([]);
  const [accurateSearch, setAccurateSearch] = useState<boolean>(false);
  const [selectedTags, setSelectedTags]: any = useState([]);
  const [bookletSelected, setBookletSelected]: any = useState([]);
  const [filterList, setFilterList]: any = useState([]);
  const [permissionList, setPermissionList]: any = useState([]);
  const [onlyDocument, setOnlyDocument]: any = useState(false);

  const [filterOptions, setFilterOptions]: any = useState([]);
  const [bookletsExpanded, setBookletsExpanded] = useState<boolean>(false);
  const [taskListDisplayed, setTaskListDisplayed] = useState([])
  const [documentList, setDocumentList] = useState<({ [key: string]: any })>([])
  const { options } = useFilter('task')
  const [searchText, setSearchText] = useState('');

  const [unfoldAll, setUnfoldAll] = useState<boolean>(false);

  const { filterTask } = useTaskFilter();

  const getDocumentList = (subProjectId: string) => {
      subProjectService.getDocumentList(subProjectId).then(({ data: documentData }) => {
          setDocumentList(documentData)
      }).catch(e => {})
  }

  const onBookletChange = (value: any) => {
    setBookletSelected(value);
    const params = filterHandler('booklet', value)
    getTaskList(subProject.id, { params: filterTask(params) })
  }

  const onTagChange = (value: any) => {
      setSelectedTags(value);
      setTagsSearchValue('');
      const params = filterHandler('tag', value)
      getTaskList(subProject.id, { params: filterTask(params) })
  }

  const onSearchChange = (value: string) => {
    const params = filterHandler('search', value);
    getTaskList(subProject.id, { params: filterTask(params) })
  }

    useEffect(() => {
        const params = filterHandler('accurateSearch', accurateSearch);
        getTaskList(subProject.id, { params: filterTask(params) });
    }, [accurateSearch]);

  const onFilterChange = (value: string) => {
    const params = filterHandler('status', value)
    getTaskList(subProject.id, { params: filterTask(params) })
  }

  const filterHandler = (type: string, value: any) => {
    const isFilterAlreadyExists = filterList.some((filter: any) => filter.type === type)

    if (isFilterAlreadyExists) {
      const updatedFilter = filterList.map((filter: any) =>
        filter.type === type ? {type, value} : filter)
      setFilterList(updatedFilter)
      return updatedFilter
    }
    setFilterList([...filterList, {type, value}])
    return [...filterList, {type, value}]
  }

  const parseTaskList = (taskList: any) => {
    taskList = Object.values(taskList);
    return taskList.map((child: any) => {
      if (child.children) {
          child.children = ArrayService.sortHierarchical(parseTaskList(child.children), "hierarchicalNumber");
      }
      return child;
    });
  };

  const getTaskList = (subProjectId: string, { params, cb }: any) => {
    subProjectService.getTaskList(subProjectId, params).then(({ data: taskData }) => {
      setUnfoldAll((taskData.numberOfTasks <= 50));

    setTaskListDisplayed(parseTaskList(Object.values(taskData.tasks)));

    const searchText = ''
    localStorage.setItem('search', searchText)
    if (cb) cb()
    }).catch((e: any) => {
      // si 403: pas d'acces / uniquement document
        setOnlyDocument(true)
        if (cb) cb()
    })
  }

  useEffect(() => {
    const projectId = match.params.projectid;
    const subProjectId = match.params.subprojectid;

      subProjectService.getTagList(subProjectId)
          .then(({ data: tagListData }: any) => {
              const tagList = tagListData.map((label: string) => {
                  return {
                      label,
                      value: label,
                  }
              })

              setTagOptions(tagList);
          })
          .catch(console.log);

    subProjectService.getAllowedBookletList(subProjectId).then(({ data: bookletData }) => {
      const bookletList = Object.keys(bookletData).map((label: string) => {
        return {
          label,
          value: label,
        }
      })

      const bookletListSelected = bookletList.filter((data: any) => bookletData[data['label']].pointage)
      setBookletOptions(bookletList)
      setBookletSelected(bookletListSelected)
      const params = filterHandler('booklet', bookletListSelected)

      getTaskList(subProjectId, {
          params: filterTask(params),
          cb: () => {
              setIsLoading(false)
          }
      })
    }).catch((e) => {
      // todo: afficher une message qu'il n'y a aucun acces
    })

    projectService.getOne(projectId)
      .then(({ data: projectData }: any) => {
        setProject(projectData)
        subProjectService.getOne(subProjectId)
          .then(({ data: subProjectData }: any) => {
            document.title = `${subProjectData.name} - Canreport`
            setSubProject(subProjectData)

            const addedOptions = subProjectData.futureDaysFilter
                .split(',')
                .filter((e: string) => e != '')
                .map((days: string) => ({ label: `A faire dans les ${days} jours`, value: `in${days}` }));
            setFilterOptions([...options, ...addedOptions])
          })
          .catch(console.log);
      })
      .catch(console.log);

    subProjectService
      .getCurrentPermissionList(subProjectId)
      .then(({ data: permissionListData }: any) => {
        setPermissionList(permissionListData
          .filter((permission: any) => permission.permissions.includes('pointage'))
          .map((permission: any) => permission.booklet)
        )
    })
    .catch(console.log);

    getDocumentList(subProjectId);
  }, [match.params.subprojectid])

    const showTaskDocuments = (taskFolder: string) => {
      setModalTaskFolder(taskFolder);
      setModalDisplayed(true);
    }

    const deleteRex = (rex: any) => {
        ReturnOfExperienceService.delete(rex.id).then(data => {
            setTaskRexes(taskRexes.filter(el => el.id !== rex.id))
        }).catch(err => {

        })
    }

    const showTaskRex = (task: ITask, rexes: any[]) => {
        setTaskRexes(rexes);
        setTargetTask(task);
        setRexModalDisplayed(true);
    }

    const showSignatureModal = (task: ITask) => {
        setTargetTask(task);
        setSignatureModalDisplayed(true);
    }

    const refreshRexes = (task: ITask) => {
        if(task.id !== undefined) taskService.getRexes(task.id).then(data => {
            setTaskRexes(data.data);
        }).catch((e: any) => {})
    };

    const getAutoHiddenSelect = () => {
      if(bookletSelected !== null && bookletSelected.length >= 3 && !bookletsExpanded) {
          return <IonRow className={"ion-align-items-center"}>
              <IonRow className={"ion-align-items-center ion-justify-content-between"} style={{ flex: 1}}>
                  <p>Plusieurs carnets selectionnés</p>
                  <IonIcon style={{ margin: 10 }} icon={closeSharp} className={"btn btn-primary"} onClick={() => setBookletSelected([])} />
              </IonRow>
              <IonButton className="search-icon-button" onClick={() => setBookletsExpanded(!bookletsExpanded)}>
                  <IonIcon icon={arrowDown} className={"btn btn-primary"} />
              </IonButton>
          </IonRow>
      } else {
          return <IonRow className={"ion-align-items-center"}>
              <Select
              isMulti
              name="colors"
              value={bookletSelected}
              onChange={onBookletChange}
              options={bookletOptions}
              className="basic-multi-select"
              classNamePrefix="select"
          />
              {bookletSelected !== null && bookletSelected.length >= 3 && bookletsExpanded && <IonButton className="search-icon-button" onClick={() => setBookletsExpanded(!bookletsExpanded)}>
                  <IonIcon icon={arrowUp} className={"btn btn-primary"} />
              </IonButton>}
          </IonRow>
      }
    }

    return (
    <IonPage className="ion-page" id="main-content">
    <IonModal isOpen={isModalDisplayed} onDidDismiss={() => setModalDisplayed(false)}>
        <p>Liste des documents</p>
        <IonRow>
            {modalTaskFolder !== "" && documentList[modalTaskFolder].length > 0 && documentList[modalTaskFolder].map((documentItem: any) => <DocumentItem key={documentItem.id} isEditable={false}
                                                                                                                                                          document={documentItem}/>)}
        </IonRow>
        <IonButton onClick={() => setModalDisplayed(false)}>Fermer</IonButton>
    </IonModal>
      <ListRexModal isOpen={isRexModalDisplayed}
                    rexes={taskRexes}
                    deleteRex={deleteRex}
                    onDismiss={() => setRexModalDisplayed(false)}
                    task={targetTask}
                    subProject={subProject}
                    refreshRexes={refreshRexes} />
        <MilestoneSignatureTaskModal isOpen={isSignatureModalDisplayed}
                      onDismiss={() => setSignatureModalDisplayed(false)}
                      onSave={() => {
                          targetTask.started = true;
                          targetTask.progress = 100
                          setSignatureModalDisplayed(false);
                      }}
                      task={targetTask}
                      subProject={subProject} />
      <IonHeader className="center-content">
        <IonToolbar>
          <NavigationMenu active="projets" backLabel={project.name} backLink={`/projet/${project.id}`} >
              <h1 id="title-in-navigation-menu">{subProject.name}</h1>
          </NavigationMenu>
        </IonToolbar>
          <Expandable>
                  <IonRow className={"ion-justify-content-end ion-align-items-center"}>
                      <IonCol size={"auto"} style={{ padding: 0 }}>
                          <IonRow className={"ion-justify-content-center ion-align-items-center carnet-select-container"}>
                              <IonSearchbar
                                  value={searchText}
                                  className={"css-yk16xz-control"}
                                  debounce={0}
                                  style={{ width: "300px", padding: 0 }}
                                  onIonChange={(e) => setSearchText(e.detail.value ?? '')}
                                  onKeyPress={(e) => e.key === "Enter" && onSearchChange(searchText)}
                                  onIonClear={() => {
                                      setSearchText('');
                                      onSearchChange('');
                                  }}
                                  placeholder="Rechercher">
                              </IonSearchbar>
                              <IonButton className="search-icon-button" onClick={() => onSearchChange(searchText)}>
                                  <IonIcon name="search-outline" className={"btn btn-primary"} />
                              </IonButton>
                          </IonRow>
                      </IonCol>
                      <IonCol size={"auto"} className={'accurate-search'} style={{ zIndex: 8 }}>
                          <IonCheckbox id={"likeEnd"} aria-labelledby="accurate-search" checked={accurateSearch} onIonChange={() => setAccurateSearch(!accurateSearch)}></IonCheckbox>
                          <IonLabel id={"accurate-search"} className={'text-black ml-2'}>Recherche titre</IonLabel>
                      </IonCol>
                      <IonCol style={{ zIndex: 8 }}>
                          <Select
                              isMulti
                              name="tags"
                              inputValue={tagsSearchValue}
                              onInputChange={(value, action) => {
                                  if (action.action === "input-change") setTagsSearchValue(value);
                              }}
                              closeMenuOnSelect={false}
                              placeholder={"Tags..."}
                              value={selectedTags}
                              onChange={onTagChange}
                              options={tagOptions}
                              className="basic-multi-select"
                              classNamePrefix="select"
                          />
                      </IonCol>
                  </IonRow>
                  <IonRow className={"ion-justify-content-end ion-align-items-center"}>
                      <IonCol style={{ flex: 1, paddingLeft: 0 }}>
                          <IonRow className="filters ion-align-items-center carnet-select-container border-1">
                              <IonCol style={{ flex: 1, minWidth: '150px', zIndex: 5, paddingLeft: 5 }}>
                                  {getAutoHiddenSelect()}
                              </IonCol>
                              <IonCol size={"auto"}>
                                  <IonButton className="add-all-icon-button" onClick={() => {
                                      setBookletSelected(bookletOptions)
                                      onBookletChange(bookletOptions)
                                  }}>
                                      <IonIcon icon={addOutline} />
                                  </IonButton>
                              </IonCol>
                          </IonRow>
                      </IonCol>
                      <IonCol size={"auto"}>
                          <IonButtons className="add-filter">
                              <IonButton>
                                  <IonIcon color="dark" icon={funnelOutline} />
                                  <select
                                      onChange={(e) => onFilterChange(e.target.value)}
                                      defaultValue={filterName}
                                      className="filter"
                                  >
                                      {filterOptions.map((option: any) => (
                                          <option key={option.value} value={option.value}>
                                              {option.label}
                                          </option>
                                      ))}
                                  </select>
                                  <IonIcon slot="end" color="dark" icon={chevronDownOutline} />
                              </IonButton>
                          </IonButtons>
                      </IonCol>
                      <IonCol size={"auto"}>
                          <IonRow className="ion-align-items-center ion-justify-content-end">
                              <Menu optionType={"subproject"}
                                    projectId={project.id} subProjectId={subProject.id}
                                    fromDetails={true}/>
                          </IonRow>
                      </IonCol>
                      <IonCol size={"auto"}>
                          <IonButton onClick={e => {
                              const params = filterHandler('search', searchText)
                              setTaskListDisplayed([]);
                              getTaskList(subProject.id, { params: filterTask(params) })
                          }}>
                              <IonIcon icon={refresh}></IonIcon>
                          </IonButton>
                      </IonCol>
                  </IonRow>
              </Expandable>
      </IonHeader>
      <IonContent className="IonPadding">
        <IonRow className={"center-content"}>
            <IonCol class={"tasks-container"}>
                {isLoading &&
                    <IonRow className="ion-justify-content-center ion-align-items-center" style={{height: '300px'}}>
                        <IonSpinner />
                    </IonRow>
                }
                <SubprojectContextProvider value={{ subProject, permissionList, showSignatureModal, showRexModal: showTaskRex, showModal: showTaskDocuments, documentList }}>
                    {!isLoading && <RecapList taskList={taskListDisplayed} unfoldAll={unfoldAll} />}
                </SubprojectContextProvider>

                {!isLoading && taskListDisplayed.length === 0 && <p className={"ion-text-center ion-margin-top"}>Aucune tâche définie</p>}

                {onlyDocument && <div style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '150px',
                }}>
                    <IonLabel style={{marginBottom: '20px'}}>
                        Consulter et modifier les documents du projets
                    </IonLabel>
                    <IonButtons>
                        <IonButton
                            style={{ position: "relative" }}
                            onClick={(e: any) => {
                                e.preventDefault()
                                //history.replace(`/projet/${project.id}/sous-projet/${subProject.id}/documentation`);
                            }}
                        >
                            <IonIcon slot="start" icon={folderOutline} />
                            <IonLabel>Documentation</IonLabel>
                            <IonIcon slot="end" icon={arrowForwardOutline} />
                        </IonButton>
                    </IonButtons>
                </div>}
            </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default SubProjectDetails;
