import React, { useState } from "react";
import "./TitleOptionSearch.scss";
import {
  IonButtons,
  IonGrid,
  IonRow,
  IonCol,
  IonSearchbar,
  IonLabel,
  IonChip,
  useIonViewWillLeave,
  IonButton,
  IonIcon,
} from "@ionic/react";
import UserMenu from "../../Menu/UserMenu";
import ProjectMenu from "../../Menu/ProjectMenu";
import SubProjectMenu from "../../Menu/SubProjectMenu";
import { userService } from "../../../services";
import { folderOutline } from "ionicons/icons";
import { useHistory } from "react-router";

export const Menu = ({
  history,
  userId,
  isUserEnabled,
  projectId,
  isArchived,
  subProjectId,
  optionType,
  fromDetails
}: {
  history?: any
  userId?: string
  isUserEnabled?: boolean
  projectId?: string
  isArchived?: boolean
  subProjectId?: string
  fromDetails? : boolean
  optionType: 'user' | 'project' | 'subproject'

}) => {
  if (optionType) {
    switch (optionType) {
      case 'user':
        return <UserMenu userId={userId} isUserEnabled={isUserEnabled} fromDetails={fromDetails} />
      case 'project':
        return userService.isAdmin() ? <ProjectMenu projectId={projectId} isArchived={isArchived} fromDetails={fromDetails} /> : <></>
      case 'subproject':
        return userService.isAdmin()
          ? <SubProjectMenu projectId={projectId} subProjectId={subProjectId} fromDetails={fromDetails} />
          : <IonButtons>
              <IonButton
                color={'primary'}
                style={{ position: "relative" }}
                onClick={(e: any) => {
                    e.preventDefault()
                    history.push(`/projet/${projectId}/sous-projet/${subProjectId}/documentation`);
                }}
              >
                <IonIcon slot="icon-only" icon={folderOutline} />
              </IonButton>
            </IonButtons>
      default:
        return null
    }
  }
  return null
}

const TitleOptionSearch: React.FC<any> = ({ title, listCount, center = false, progress, userId, isUserEnabled, projectId, isArchived, subProjectId, optionType, onSearchChange, fromDetails, button = false, displayMenu = true }) => {
  const [searchText, setSearchText] = useState('')
  const history = useHistory()
  useIonViewWillLeave(() => {
    setSearchText('')
  })

  return (
    <IonButtons>
      <IonGrid style={{ maxWidth: '100%'}}>
        <IonRow className="ion-align-items-center ion-justify-content-between">
          <IonCol size="auto" class={center ? "ion-justify-content-center" : ''} style={{display: 'flex'}}>
            <IonRow className="ion-align-items-center">
              <h1 style={{ margin: 0, marginRight: "8px", maxWidth: 450, maxHeight: 35, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{title || " "}</h1>
              {listCount && <IonChip color="medium" style={{
                cursor: "default",
                position: 'relative',
                top: '-1px'
              }}>
                <IonLabel>{listCount}</IonLabel>
              </IonChip>}
              {progress && <IonChip color={progress === '100' ? "success" : "primary"} style={{
                cursor: "default",
                position: 'relative',
                top: '-1px'
              }}>
              <IonLabel>{Math.trunc(progress || 0)} %</IonLabel>
              </IonChip>}
            </IonRow>
          </IonCol>
          {displayMenu && <IonCol size={"auto"}>
            <IonRow className="ion-align-items-center ion-justify-content-end">
              <Menu history={history} optionType={optionType} userId={userId} isUserEnabled={isUserEnabled}
                    projectId={projectId} isArchived={isArchived} subProjectId={subProjectId}
                    fromDetails={fromDetails}/>
            </IonRow>
          </IonCol>
          }
        </IonRow>
        <IonRow className="ion-align-items-center ion-justify-content-end">
          {onSearchChange && (
              <IonSearchbar
                  className={button ? 'hide-icon' : ''}
                  value={searchText}
                  style={{ width: "300px" }}
                  // onIonChange={onSearchChange}
                  onIonChange={(e) => {
                    setSearchText(e.detail.value ?? '')
                    if(!button) onSearchChange(e.detail.value)
                  }}
                  placeholder="Rechercher"></IonSearchbar>
          )}
          {button && (
            <IonButton className="search-icon-button" onClick={() => onSearchChange(searchText)}>
              <IonIcon name="search-outline" className={"btn btn-primary"}></IonIcon>
            </IonButton>)}
        </IonRow>
      </IonGrid>
    </IonButtons>
  );
};

export default TitleOptionSearch;
